<template>
  <div>
    <div v-if="permissions['importacao.index'].permissao">
      <b-button
        v-if="permissions['importacao.descontos.store'].permissao"
        v-b-modal.import-modal
        variant="primary"
      >
        Importar
      </b-button>
      <!-- Iniciando a classe da barra de tarefa -->
      <b-row class="mt-1">
        <b-col>
          <b-row>
            <b-col class="text-center">
              <h5>{{ bar.label }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-progress
                :key="bar.variant"
                animated
                :value="bar.value"
                :variant="bar.variant"
                :class="'progress-bar-' + bar.variant"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Fim da classe da barra progresso -->
      <b-modal
        id="import-modal"
        title="Importação"
        no-close-on-backdrop
        ok-title="Importar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @ok="enviarImportacao"
      >
        <div id="formulario">
          <b-form-file
            v-model="planilha"
            class="mt-2"
            browse-text="Buscar"
            placeholder="Selecione a planilha a ser importado"
          />
        </div>
      </b-modal>
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormFile,
  BButton,
  BProgress,
} from 'bootstrap-vue'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BModal,
    BFormFile,
    BButton,
    notAuthorized,
    BProgress,
  },
  data() {
    return {
      permissions: null,
      importacao: null,
      planilha: null,
      bar: {
        label: '',
        value: 0,
        variant: 'success',
      },
    }
  },
  directive: {
    'b-modal': VBModal,
  },
  created() {
    this.permissions = JSON.parse(localStorage.permissoes).importacao
  },
  mounted() {
    this.$bvModal.show('import-modal')
  },

  methods: {
    async enviarImportacao() {
      this.bar.label = 'IMPORTANDO INSUCESSOS'
      const formData = new FormData()
      formData.append('importar', this.planilha)
      axios.post('api/v1/insucessos/importar', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.$swal({
          title: 'sucesso',
          text: 'Planilha importada com Sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
          buttonStyling: false,
        })
        this.bar.value = 0
        this.bar.label = 'Planilha importada com Sucesso'
      }).catch(() => {
        this.$swal({
          title: 'Error!',
          text: 'Erro durante a importação da planilha tente novamente!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
        this.bar.value = 0
        this.bar.label = 'Erro durante a importação da planilha'
      })
      for (let i = 0; i < 100; i++) {
        setTimeout(() => {
          this.bar.value = i
        }, 2500)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
